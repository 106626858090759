import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'utils/navigate'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Header, InputSearch, Footer, SEO } from 'components'
import blackLogo from 'assets/fmfLogoBlack.png'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import { actionCreators, selectMenu, selectProducts } from 'state/HomePage/reducer'
import * as styles from './404.module.scss'

if (typeof window !== 'undefind' && typeof document !== 'undefined') {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const HomePage = ({ menu, products, setSearchValue }) => {
  const { landing, shared } = useSiteMetadata()
  const [isMount, setMount] = useState(false)

  const campaignName = landing.CAMPAIGN.name
  const quickSearchKeys = shared.QUICK_SEARCH_KEYS

  useEffect(() => {
    setMount(true)
  },[])

  const onChangeSearch = debouncedValue => {
    setSearchValue()
    navigate(`/search/${debouncedValue.trim()}`)
  }

  if(!isMount) {
    return null
  }

  return (
    <div className={styles.homePage}>
      <SEO pathname='/' title={campaignName} />
      <Header isNeedDescription={!!menu?.selectedCollection} gap={true} />

      <div className={styles.content}>
        <section className={styles.searchBanner}>
          <h1 className={styles.title}>Page Not Found</h1>
          <h2 className={styles.bannerTitle}>The page you were looking for could not be found.</h2>
          <h2 className={styles.bannerTitle}>It might have been removed, renamed, or did not exist in the first place.</h2>
          <b className={styles.bannderDescription}>Don’t give up! Search what you are looking for here:</b>
          <div className={styles.searchContainer}>
            <InputSearch
              placeholder='Search by keyword'
              onChange={onChangeSearch}
              searchKeys={['productDisplayName', 'collectionName', 'name', 'displayName', 'description']}
              searchSource={products}
              type='primary'
            />
          </div>
          {quickSearchKeys && (
            <div className={styles.quickSearch}>
              <ul className={styles.quickSearchList}>
                {quickSearchKeys.split(',').map((key, indx) => (
                  <li onClick={() => onChangeSearch(key)} className={styles.quickSearchItem} key={`key${indx}`}>
                    {key.trim()}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>
      </div>

      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  products: selectProducts(state),
})

const mapDispatchToProps = {
  setSearchValue: actionCreators.setSearchValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
