// extracted by mini-css-extract-plugin
export var homePage = "_404-module--homePage--K94aD";
export var content = "_404-module--content--1SFxU";
export var searchBanner = "_404-module--searchBanner--3z9Dd";
export var title = "_404-module--title--2sRC_";
export var bannerTitle = "_404-module--bannerTitle--3e64t";
export var searchContainer = "_404-module--searchContainer--tnO76";
export var quickSearch = "_404-module--quickSearch--3MSIm";
export var quickSearchList = "_404-module--quickSearchList--3gcWT";
export var quickSearchItem = "_404-module--quickSearchItem--3K1Vz";
export var footerContainer = "_404-module--footerContainer--1BO_G";
export var bannderDescription = "_404-module--bannderDescription--uAL5p";